exports.components = {
  "component---src-pages-karte-js": () => import("./../../../src/pages/karte.js" /* webpackChunkName: "component---src-pages-karte-js" */),
  "component---src-pages-kategorien-js": () => import("./../../../src/pages/kategorien.js" /* webpackChunkName: "component---src-pages-kategorien-js" */),
  "component---src-pages-orte-js": () => import("./../../../src/pages/orte.js" /* webpackChunkName: "component---src-pages-orte-js" */),
  "component---src-pages-regionen-js": () => import("./../../../src/pages/regionen.js" /* webpackChunkName: "component---src-pages-regionen-js" */),
  "component---src-templates-kategorie-js": () => import("./../../../src/templates/kategorie.js" /* webpackChunkName: "component---src-templates-kategorie-js" */),
  "component---src-templates-ort-js": () => import("./../../../src/templates/ort.js" /* webpackChunkName: "component---src-templates-ort-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-record-js": () => import("./../../../src/templates/record.js" /* webpackChunkName: "component---src-templates-record-js" */),
  "component---src-templates-region-js": () => import("./../../../src/templates/region.js" /* webpackChunkName: "component---src-templates-region-js" */)
}

